<template>
  <div>
    <!-- top -->
    <van-sticky>
      <van-nav-bar title="账变纪录" left-arrow @click-left="$router.push('/')">
        <template #title>
          <div class="navTitle">
            <van-dropdown-menu
              class="transDrop"
              style="background-color: transparent !important"
            >
              <van-dropdown-item v-model="value1" :options="option1" />
            </van-dropdown-menu>
            <van-icon name="arrow-down" class="dropArrow" />
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div
      class="wrap"
      :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }"
    >
      <div class="contentWrap">
        <div class="cWrap" v-if="value1 == 1">
          <date-range-picker
            style="width: 100%; margin-bottom: 15px"
            class="timePick"
            ref="picker"
            opens="center"
            :timePicker="false"
            v-model="dates"
            :locale-data="local"
            :ranges="false"
            :maxDate="new Date()"
            :autoApply="true"
          >
            <template
              slot="input"
              v-if="dates.startDate == ''"
              style="min-width: 350px; padding-left: 15px"
            >
              <span style="min-width: 350px; padding-left: 15px">
                {{ "请输入日期时间" }}
              </span>
            </template>
          </date-range-picker>
          <van-cell-group
            inset
            :value="'¥' + deposit.amount"
            v-for="deposit in withdrawList"
            :key="deposit.id"
            style="margin: 0; margin-bottom: 8px"
            class="logWrap"
            @click="
              currentGropId == deposit.id
                ? (currentGropId = '')
                : (currentGropId = deposit.id)
            "
            :class="{ active: currentGropId == deposit.id }"
          >
            <p
              class="orderId"
              @click.stop
              v-clipboard:copy="deposit.order_id"
              v-clipboard:success="onCopy"
            >
              <span class="title">订单编号: </span>
              <span class="id">
                {{ deposit.order_id }}
              </span>
              <i class="far fa-clone copyIcon"></i>
            </p>
            <div class="left">
              <p class="title">{{ deposit.type }}</p>
              <p class="date">{{ deposit.created_at }}</p>
            </div>
            <div class="right">
              <div class="balance">
                <p
                  style="white-space: nowrap"
                  :class="{ lose: deposit.amount_alter * 1 < 0 }"
                >
                  ¥ {{ deposit.amount_alter | thousands }}
                </p>
                <p style="white-space: nowrap; color: #999">
                  余额: {{ deposit.amount_changed | thousands }}
                </p>
              </div>
              <van-icon name="arrow-down" />
            </div>
          </van-cell-group>
        </div>
        <div class="cWrap" v-else>
          <date-range-picker2
            style="width: 100%; margin-bottom: 15px"
            class="timePick"
            ref="picker"
            opens="center"
            :timePicker="false"
            v-model="dates1"
            :locale-data="local"
            :ranges="false"
            :maxDate="new Date()"
            :autoApply="true"
          >
            <template
              slot="input"
              v-if="dates1.startDate == ''"
              style="min-width: 350px; padding-left: 15px"
            >
              <span style="min-width: 350px; padding-left: 15px">
                {{ "请输入日期时间" }}
              </span>
            </template>
          </date-range-picker2>
          <van-cell-group
            inset
            :value="'¥' + deposit.amount"
            v-for="deposit in depositList"
            :key="deposit.id"
            style="margin: 0; margin-bottom: 8px"
            class="logWrap"
            @click="
              currentGropId == deposit.id
                ? (currentGropId = '')
                : (currentGropId = deposit.id)
            "
            :class="{ active: currentGropId == deposit.id }"
          >
            <p
              class="orderId"
              @click.stop
              v-clipboard:copy="deposit.order_id"
              v-clipboard:success="onCopy"
            >
              <span class="title">订单编号: </span>
              <span class="id">
                {{ deposit.order_id }}
              </span>
              <i class="far fa-clone copyIcon"></i>
            </p>
            <div class="left">
              <p class="title">{{ deposit.type }}</p>
              <p class="date">{{ deposit.created_at }}</p>
            </div>
            <div class="right">
              <div class="balance">
                <p
                  style="white-space: nowrap"
                  :class="{ lose: deposit.amount_alter * 1 < 0 }"
                >
                  ¥ {{ deposit.amount_alter | thousands }}
                </p>
                <p style="white-space: nowrap; color: #999">
                  余额: {{ deposit.amount_changed | thousands }}
                </p>
              </div>
              <van-icon name="arrow-down" />
            </div>
          </van-cell-group>
        </div>
      </div>
    </div>
    <foot />
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import DateRangePicker2 from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import foot from "../../components/footer";
export default {
  components: { foot, DateRangePicker, DateRangePicker2 },
  data() {
    return {
      value1: 1,
      wait: false,
      current_page: 1,
      option1: [
        { text: `保证金${this.$returnBuySel("流水")}`, value: 1 },
        { text: `红利${this.$returnBuySel("流水")}`, value: 0 },
      ],
      depositPaginate: {},
      depositList: [],
      depositPage: 1,
      withdrawList: [],
      withdrawPaginate: {},
      withdrawPage: 1,
      isLoading: false,
      next_page: true,
      currentGropId: "",
      dates: { startDate: "", endDate: "" },
      dates1: { startDate: "", endDate: "" },
      local: {
        direction: "ltr",
        format: "yyyy-mm-dd",
        separator: " - ",
        applyLabel: "确认",
        weekLabel: "W",
        cancelLabel: "取消",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
        monthNames: [
          "01月",
          "02月",
          "03月",
          "04月",
          "05月",
          "06月",
          "07月",
          "08月",
          "09月",
          "10月",
          "11月",
          "12月",
        ],
        firstDay: 0,
      },
    };
  },
  watch: {
    value1() {
      this.current_page = 1;
      if (this.value1 == 1) {
        this.getWithdraw();
      } else {
        this.getDeposit();
      }
    },
    depositPage() {
      this.getDeposit();
      window.scrollTo(0, 0);
    },
    withdrawPage() {
      this.getWithdraw();
      window.scrollTo(0, 0);
    },
    dates() {
      this.current_page = 1;
      this.getWithdraw();
    },
    dates1() {
      this.current_page = 1;
      this.getDeposit();
    },
  },
  methods: {
    onCopy() {
      this.$toast.success("已复制");
    },
    onRefresh() {
      this.$store.dispatch("overlayHandler", true);
      this.getDeposit();
      this.getWithdraw();
    },
    getDeposit() {
      let params = {};
      if (this.dates1.startDate) {
        params.start = this.$returnTime(this.dates1.startDate) + " 00:00:00";
        params.end = this.$returnTime(this.dates1.endDate) + " 23:59:59";
      }
      params.page = this.current_page;
      this.$http.get(`/mobile/bonusLogs`, { params }).then((res) => {
        if (res.data.code == 0) {
          this.wait = false;
          let listData = JSON.parse(JSON.stringify(this.depositList));
          if (params.page == 1) {
            listData = [];
          }
          res.data.data.forEach((data) => {
            let have = false;
            this.depositList.forEach((e) => {
              if (e.id == data.id) {
                have = true;
              }
            });
            if (!have) {
              listData.push(data);
            }
          });
          this.depositList = listData;
          this.next_page = res.data.next_page;
          this.isLoading = false;
          this.$store.dispatch("overlayHandler", false);
        }
      });
    },
    getWithdraw() {
      let params = {};
      if (this.dates.startDate) {
        params.start = this.$returnTime(this.dates.startDate) + " 00:00:00";
        params.end = this.$returnTime(this.dates.endDate) + " 23:59:59";
      }
      params.page = this.current_page;
      this.$http.get(`/mobile/balanceLogs`, { params }).then((res) => {
        if (res.data.code == 0) {
          this.wait = false;
          let listData = JSON.parse(JSON.stringify(this.withdrawList));
          if (params.page == 1) {
            listData = [];
          }
          res.data.data.forEach((data) => {
            let have = false;
            this.withdrawList.forEach((e) => {
              if (e.id == data.id) {
                have = true;
              }
            });
            if (!have) {
              listData.push(data);
            }
          });
          this.withdrawList = listData;
          this.next_page = res.data.next_page;
          // this.withdrawPaginate = res.data.paginate;
          this.isLoading = false;
          this.$store.dispatch("overlayHandler", false);
        }
      });
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          if (this.wait) return;
          this.wait = true;
          this.current_page += 1;
          if (this.value1 == 1) {
            this.getWithdraw();
          } else {
            this.getDeposit();
          }
        }
      }
    },
  },
  created() {
    if (this.$route.query.value || this.$route.query.value == 0) {
      this.value1 = this.$route.query.value * 1;
    }
    this.getDeposit();
    this.getWithdraw();
  },

  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  // background-image: url("/home-page_bg.png");
  // background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alterText {
  color: yellowgreen;
}
.reduceText {
  color: red;
}
.navTitle {
  display: flex;
  align-items: center;
  .dropArrow {
    font-size: 16px;
    font-weight: bold;
  }
}

.dropdown {
  margin-bottom: 15px;
  position: relative;
  .arrow {
    position: absolute;
    right: 10px;
    top: 35%;
  }
  .van-dropdown-menu__title {
    margin-right: auto !important;
  }
  .van-dropdown-menu__bar {
    border-radius: 10px !important;
  }
}
.contentWrap {
  width: 285px;
}
.dateWrap {
  display: flex;
  margin-bottom: 10px;
}
.logWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 60px;
  flex-wrap: wrap;
  transition: 0.3s ease-in-out;
  .orderId {
    height: 0;
    overflow: hidden;
  }
  .left {
    width: 60%;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .date {
      font-size: 12px;
      color: #999999;
    }
  }
  .right {
    width: 40%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    .balance {
      color: #009aaa;
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
      text-align: right;
      p {
        &:first-child {
          font-size: 14px;
        }
      }
    }
    i {
      color: #999999;
      font-weight: bold;
      font-size: 16px;
      transition: 0.3s ease-in-out;
    }
  }
}
.logWrap.active {
  height: 100px;
  .orderId {
    height: auto;
    width: 100%;
    font-size: 12px;
    padding-right: 20px;
    display: flex;
    position: relative;
    .copyIcon {
      position: absolute;
      right: 0px;
      font-size: 14px;
    }
    .title {
      color: #999999;
      white-space: nowrap;
    }
    .id {
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .right {
    i {
      transform: rotate(180deg);
    }
  }
}
.rightSelect {
  display: flex;
  align-items: center;
  color: #009aaa;
  .icon {
    color: #009aaa !important;
  }
}
.leftSelect {
  position: fixed;
  top: 46px;
  bottom: 0px;
  right: 0px;
  left: 0;
  background-color: rgba(black, 0.8);
  z-index: 999;
  overflow: scroll;
  .selectContent {
    width: 80%;
    background-color: #fff;
    position: fixed;
    right: 0;
    top: 46px;
    bottom: 0;
  }
}
.drawerTime {
  border: 0.5px solid #cccccc;
  color: #cccccc;
}
.drawerWrap {
  color: #333333 !important;
  .content {
    .title {
      font-size: 16px;
      font-weight: 500;
      background-color: rgb(242, 242, 242);
      line-height: 50px;
      padding-left: 10px;
    }
  }
  .drawerContentWrap {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    label {
      color: #333333;
      margin-top: 5px;
      line-height: 30px;
    }
    .drawerTime {
      width: 115px;
      // height: 24px;
    }
    .pickerWrap {
      display: flex;
      align-items: center;
      .datePick {
        width: 115px;
        height: 30px;
        align-items: center;
        line-height: 30px;
        border: 0.5px solid #cccccc;
        text-align: center;
        color: #cccccc;
      }
    }
  }
  .footBtnWrap {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      width: 80px;
      height: 24px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .reset {
      border: 0.5px solid #009aaa;
      color: #009aaa;
    }
    .use {
      background-color: #009aaa;
      color: white;
    }
  }
}
.lose {
  color: red !important;
}
</style>
